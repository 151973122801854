import React from 'react'

import Container from 'react-bootstrap/Container';

const Sitemap = () => {
    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Sitemap</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Sitemap</li>
                    </ul>
                </div>
            </section>
            <section className="entry sitemap">
                <Container>

                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/company">Company</a></li>
                        <li><a href="/ready-mix-concrete">Ready Mix Concrete</a></li>
                        <li><a href="/gallery">Gallery</a></li>
                        <li><a href="/contact-us">Contact Us</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>

                </Container>
            </section>
        </div >
    )
}

export default Sitemap
