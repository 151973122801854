import React from 'react'
import { Carousel } from 'react-bootstrap'
import Slider1 from '../assets/images/conmix-infra-ready-mixed-concrete.png'
import Slider2 from '../assets/images/concrete-batching-plants.png'
import Slider3 from '../assets/images/concrete-mixes-supplier-conmixinfra.png'

const Slider = () => {
    return (
        <div>
            <div className="slider">
                <Carousel controls={false} interval={null}>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider1} alt="conmix infra ready mixed concrete" />
                        <Carousel.Caption><h1>Manufactures and Supplies <br /> Ready Mix Concrete</h1></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider2} alt="concrete batching plants" />
                        <Carousel.Caption><h2>Manufactures and Supplies <br /> Ready Mix Concrete</h2></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider3} alt="concrete mixes supplier conmixinfra" />
                        <Carousel.Caption><h2>Manufactures and Supplies <br /> Ready Mix Concrete</h2></Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default Slider
