import React, { useState } from 'react'
import { URL } from '../config'
import { Alert, Col, Row, Form } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import isEmail from 'validator/lib/isEmail'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ContactForm = () => {
    const to_email = "info@conmixinfra.com";
    const cc = 'arkayappsseo@gmail.com';
    const title = "Website Inquiry";
    const subject = "Contact Form - Cornmix-Infra";
    let history = useHistory();
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState(null)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [mobile, setMobile] = useState(null)
    const [message, setMessage] = useState(null)
    const template = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Cornmix-Infra</title>
    <style>
        body {
            line-height: 1.4;
            font-family: sans-serif;
            background-color: #f6f6f6;
        }
        p {
            margin: 0;
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: normal;
            font-family: sans-serif;
        }
        hr {
            border: 0;
            border-bottom: 1px solid #b9b9b9;
            margin: 10px 0;
        }
        .logo {
            margin-top: 1rem;
            margin-bottom: 1rem;
            width:180px;
          
        }
        .text-center {
            text-align: center !important;
        }
        .content {
            Margin: 0 auto;
            display: block;
            max-width: 580px;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
        }
        .innerwrap {
            box-sizing: border-box;
            padding: 20px 20px 10px 20px;
            background: #f2f2f2;
        }
        .card-header {
            background:#0061C3;
            color: #fff;
            text-align: center;
            padding: 20px;
            font-size: 16px;
        }
        .card-header p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            margin-bottom: unset;
        }
        .card-body {
            background: #ffffff;
            border-radius: 3px;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-bottom: 3px solid #0061C3;
        }
        .card-body p strong {
            color: #333;
        }
        .card-body p strong.heading {
            color:#2A2765;
        }
        .copyright {
            color: #999999;
            font-size: 12px;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    </style>
</head>
<body>
    <div class="content">
        <div class="text-center">
            <img src="https://conmixinfra.com/logo-color.png" alt="logo" class="logo">
        </div>
        <div class="card-header">
            <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
            <p>You Received an Inquiry !</p>
        </div>
        <div class="card-body">
            <p><strong>Name : </strong>${name}</p>
            <p><strong>Message : </strong>${message}</p>
            <hr>
            <p><strong class="heading">Contact Information :</strong></p>
            <div class="innerwrap">
                <p><strong>Mobile No: </strong>${mobile}</p>
                <p><strong>Email: </strong>${email}</p>
            </div>
        </div>
        <div class="copyright">
            <p>&copy; 2023 Cornmix-Infra</p>
        </div>
    </div>
</body>
</html>`;

    const mailError = (<p>{error}</p>)

    const handleName = (e) => {
        const value = e.target.value
        setName(value)
        setError(null)
    }
    const handleEmail = (e) => {
        var value = e.target.value
        value = ltrim(rtrim(value))
        setEmail(value)
        if (!isEmail(value)) {
            setError(<Alert variant={'danger'}>Please enter a valid email</Alert>)
            setDisabled(true)
        }
        else {
            setError(null)
            setDisabled(false)
        }
    }
    const handleMobile = (value) => {
        setMobile(value)
        setError(null)
    }
    const handleMessage = (e) => {
        const value = e.target.value
        setMessage(value)
        setError(null)
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        if (name === null) {
            setError(<Alert variant={'danger'}>Name Required</Alert>)
        } else if (mobile === null) {
            setError(<Alert variant={'danger'}>Contact Number Required</Alert>)
        } else if (message === null) {
            setError(<Alert variant={'danger'}>Message Required</Alert>)
        } else {
            setDisabled(true)
            const data = {
                title: title,
                to_email: to_email,
                cc: cc,
                template: template,
                subject: subject,
            };
            const headerConfig = {
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
            };
            const url = "https://api.sendmail.adhyaynam.in/send-mail";
            axios.post(url, data, headerConfig).then(response => {
                document.getElementById('contact-us').reset()
                setDisabled(false)
                history.push('/thank-you')
            }).catch(error => {
                if (error.response) {
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    console.log(error);
                }
                setDisabled(false)
            })
        }
    }
    return (
        <div>
            <Form className="contact-form" id="contact-us" onSubmit={handleFormSubmit}>
                <Row>
                    <Col md={12}>
                        <TextField className="form-control mt-unset" label="Name" size="small" variant="outlined" required inputProps={{ minLength: 3, maxLength: 50 }} onChange={handleName} />
                    </Col>
                    <Col md={6}>
                        <TextField className="form-control" label="Email" size="small" variant="outlined" inputProps={{ minLength: 3, maxLength: 100 }} onChange={handleEmail} />
                    </Col>
                    <Col md={6}>
                        <PhoneInput country={'in'} onChange={handleMobile} inputProps={{ minLength: 3, maxLength: 18, required: true }} />
                    </Col>
                    <Col md={12}>
                        <TextField multiline rows={4} className="form-control" size="small" label="Message" variant="outlined" required inputProps={{ minLength: 3, maxLength: 200 }} onChange={handleMessage} />
                    </Col>
                    {mailError}
                    <Col md={12}>
                        <Button type="submit" disabled={disabled}>SEND MESSAGE</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ContactForm
