import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Client1 from '../assets/images/clients/anish-infracon-pvt-ltd.png'
import Client2 from '../assets/images/clients/krishna-engineering-construction.png'
import Client3 from '../assets/images/clients/mr-construction.png'
import Client4 from '../assets/images/clients/purnima-builders.png'
import Client5 from '../assets/images/clients/suroj-buildcon.png'

const CarouselClients = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings}>
                <div className="client">
                    <img src={Client1} alt="anish infracon pvt ltd logo" className="img-fluid" />
                </div>
                <div className="client">
                    <img src={Client2} alt="krishna engineering construction logo" className="img-fluid" />
                </div>
                <div className="client">
                    <img src={Client3} alt="mr construction logo" className="img-fluid" />
                </div>
                <div className="client">
                    <img src={Client4} alt="purnima builders logo" className="img-fluid" />
                </div>
                <div className="client">
                    <img src={Client5} alt="suroj buildcon logo" className="img-fluid" />
                </div>
            </Slider>
        </div>
    )
}

export default CarouselClients
