// Core
import React from 'react'
// Library
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// Components
import Header from './components/Header'
import Footer from './components/Footer'
// Pages
import Homepage from './pages/Homepage'
import Company from './pages/Company'
import Rmc from './pages/Rmc'
import Gallery from './pages/Gallery'
import ContactUs from './pages/ContactUs'
import Sitemap from './pages/Sitemap'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ThankYou from './pages/ThankYou'

function App() {
    return (
        <div>
            <Router>
                <Header />
                <Switch>
                    <Route exact path='/' component={Homepage} />
                    <Route exact path='/company' component={Company} />
                    <Route exact path='/ready-mix-concrete' component={Rmc} />
                    <Route exact path='/gallery' component={Gallery} />
                    <Route exact path='/contact-us' component={ContactUs} />
                    <Route exact path='/sitemap' component={Sitemap} />
                    <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                    <Route exact path='/thank-you' component={ThankYou} />
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
