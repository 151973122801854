import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CarouselTestimonials = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        nextArrow: <ArrowForwardIcon />,
        prevArrow: <ArrowBackIcon />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="testimonials">
            <Slider {...settings}>
                <div>
                    <div className="testimonial">
                        <FormatQuoteIcon />
                        <p>Conmix RMC supply RMC on our construction site. Doorstep RMC can save a lot of time and cost. Thanks.</p>
                        <h4>Sunil Shah</h4>
                    </div>
                </div>
                <div>
                    <div className="testimonial">
                        <FormatQuoteIcon />
                        <p>Ready Mix Concrete on my construction saves a lot of time. Conmix provides RMC and any time when we need our construction site.</p>
                        <h4>Rajesh Shah</h4>
                    </div>
                </div>
            </Slider>
        </div>
    )
}


export default CarouselTestimonials
