// Core
import React from 'react'
import { Link } from 'react-router-dom'
import Slider from '../components/Slider'
import { Container, Col, Row } from 'react-bootstrap'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import About from '../assets/images/about-conmixinfra.png'
import Vehicle1 from '../assets/images/vehicle-1.svg'
import Vehicle2 from '../assets/images/vehicle-2.svg'
import CarouselTestimonials from '../components/CarouselTestimonials'
import CarouselClients from '../components/CarouselClients'
import GetInTouch from '../components/GetInTouch'

import Marquee from "react-fast-marquee";

import { benefits } from '../Benefits';

const Homepage = () => {
    return (
        <div>
            <Slider />
            <section className="entry pb-unset">
                <Container>
                    <Row>
                        <Col md={5}>
                            <img src={About} alt="About Conmix Infra" className="img-fluid my-auto" />
                            <div className="info">
                                <h4><span>10</span> Years of Experience</h4>
                            </div>
                        </Col>
                        <Col md={7}>
                            <h2>About Us</h2>
                            <h3>We Are The Leader In Ready Mix ERP Manufacturing</h3>
                            <p>At Conmix Infra, Ready Mixed Concrete (RMC) is produced in its fully automated and computerized concrete batching plants at multiple locations across Bharuch. Conmix Infra manufactures and supplies concrete mixes, which are designed in its well-equipped laboratories as per established procedures to comply with various provisions of Indian Standards.</p>
                            <Link to="/company" className="btn btn-primary">Know More <ArrowRightAltIcon /></Link>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Marquee direction="left" gradientWidth={10}>
                <img src={Vehicle1} alt="Vehicle" />
            </Marquee>
            <section className="section-bg bg-1">
                <Container>
                    <div className="text-center">
                        <h2>RMC BENEFITS</h2>
                        <h3 className="type-2">Benefits Of Ready Mixed Concrete Over Site Mixed Concrete</h3>
                    </div>
                    <Row>
                        {benefits.map((benefit, index) => {
                            return (
                                <Col md={6}>
                                    <div className="benefits">
                                        <img src={benefit.icon} alt={benefit.name} />
                                        <h4>{benefit.name}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: benefit.description.substring(0, 105) + '...' }} />
                                        <Link to="/ready-mix-concrete">Read More</Link>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle2} alt="Vehicle" />
                </Marquee>
            </section>
            <GetInTouch />
            <section className="section-bg">
                <Container>
                    <div className="text-center">
                        <h2>Client Testimonials</h2>
                        <h3 className="type-2">What People Says About Us</h3>
                    </div>
                    <CarouselTestimonials />
                </Container>
            </section>
            <section className="section-bg">
                <Container>
                    <CarouselClients />
                </Container>
            </section>
        </div>
    )
}

export default Homepage
