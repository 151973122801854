import React from 'react'

import Container from 'react-bootstrap/Container';

const ThankYou = () => {
    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Thank You</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Thank You</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>

                    <h2 className="text-center">Thank You For Your Message..!!</h2>


                    <p className="text-center">Message Sent Successfully! We have recieved your mail and we will contact you shortly.</p>


                    <p className="text-center">Click to go on <a href="/">Homepage</a></p>

                </Container >
            </section >
        </div >
    )
}

export default ThankYou
