import React from 'react'
import { Link } from 'react-router-dom'
import About from '../assets/images/about-conmixinfra.png'
import Mission from '../assets/images/conmixinfra-mission.png'
import { Container, Col, Row } from 'react-bootstrap'
import Vehicle1 from '../assets/images/vehicle-1.svg'
import Chirag from '../assets/images/team/chirag-zaveri.png'
import Arunsinh from '../assets/images/team/arunsinh-gohil.png'
import Design from '../assets/images/des-1.png'
import CarouselClients from '../components/CarouselClients'
import GetInTouch from '../components/GetInTouch'
import Marquee from "react-fast-marquee";

const Company = () => {
    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Company</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Company</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>
                    <Row>
                        <Col md={5}>
                            <img src={About} alt="About Conmix Infra" className="img-fluid my-auto" />
                            <div className="info">
                                <h4><span>10</span> Years of Experience</h4>
                            </div>
                        </Col>
                        <Col md={7}>
                            <h2>About Us</h2>
                            <h3>We Are The Leader In Ready Mix ERP Manufacturing</h3>
                            <p>At Conmix Infra, Ready Mixed Concrete (RMC) is produced in its fully automated and computerized concrete batching plants at multiple locations across Bharuch. Conmix Infra manufactures and supplies concrete mixes, which are designed in its well-equipped laboratories as per established procedures to comply with various provisions of Indian Standards.</p>
                            <p>Conmix Infra experienced logistics team works closely with you to ensure that the concrete of requisite quality is delivered on time and in the appropriate quantities.</p>
                            <p>Conmix Infra has the experience and the ability to design, produce and deliver a wide range of ready mixed concrete variants to meet specific requirements.</p>
                            <p>Do <Link to="/contact-us">Contact Us</Link> with details of your requirements, and our experts will get in touch with you to discuss the way forward.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <CarouselClients />
                </Container>
            </section>
            <Marquee direction="left" gradientWidth={10}>
                <img src={Vehicle1} alt="Vehicle" />
            </Marquee>
            <section className="section-bg">
                <Container>
                    <Row className="justify-content-between">
                        <Col md={6} className="my-auto">
                            <h2>Mission</h2>
                            <h3>Our Mission And Vision</h3>
                            <p>High Quality of Ready-mix concrete. Ready Mix Concrete will save labour costs for the construction industry. Our Vission to provide easily and effectively provide RMC at your doorstep.</p>
                            <p>We have experienced engineers who guide you for specific requirements and quality control of RMC.</p>
                            <p>Let take a short call with us for a business consultant.</p>
                        </Col>
                        <Col md={5}>
                            <img src={Mission} alt="Conmix Infra Mission" className="img-fluid my-auto" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <div className="text-center">
                        <h2>OUR TEAM</h2>
                        <h3 className="type-2">Board of Directors</h3>
                    </div>
                    <Row className="justify-content-md-center">
                        <Col md={3} className="team">
                            <img src={Chirag} alt="Chirag Zaveri" className="img-fluid profile" />
                            <img src={Design} alt="Design" className="design" />
                            <h4>Chirag Zaveri</h4>
                            <p>CEO</p>
                        </Col>
                        <Col md={3} className="team">
                            <img src={Arunsinh} alt="Arunsinh Gohil" className="img-fluid profile" />
                            <img src={Design} alt="Design" className="design" />
                            <h4>Arunsinh Gohil</h4>
                            <p>LEADER</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <GetInTouch />
        </div>
    )
}

export default Company
