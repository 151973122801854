import React from 'react'
import RmcImg from '../assets/images/ready-mix-concrete.png'
import Design from '../assets/images/des-2.png'
import Vehicle2 from '../assets/images/vehicle-2.svg'
import { Container, Col, Row } from 'react-bootstrap'
import GetInTouch from '../components/GetInTouch'
import Machinery from '../components/Machinery'
import Marquee from "react-fast-marquee";

import { benefits } from '../Benefits';

const Rmc = () => {
    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Ready Mix Concrete</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Ready Mix Concrete</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>
                    <Row>
                        <Col md={5} className="position-relative">
                            <img src={RmcImg} alt="Ready Mix Concrete manufacturers" className="img-fluid my-auto" />
                            <img src={Design} alt="Design" className="des-2" />
                        </Col>
                        <Col md={7} className="my-auto">
                            <h2>RMC</h2>
                            <h3>RMC Services</h3>
                            <p>We, Conmix Infra, are the certified RMC or Ready Mix Concrete manufacturers in Dahej & Viliyat (Bharuch).</p>
                            <p>We manufacture a wide range of ready-mix concrete to suit every type of concreting job and help our customers meet today’s construction needs.</p>
                            <p>We have earned a reputation for manufacturing the most technologically advanced RMC or Ready Mix Concrete solutions. Our RMC include automatic, transit mixers, concrete pumps and fully equipped laboratories. Our team of professionals modify the strength and workability properties of ready-mix concrete through the addition of modifying agents.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section-bg bg-1">
                <Container>
                    <div className="text-center">
                        <h2>RMC BENEFITS</h2>
                        <h3 className="type-2">Benefits Of Ready Mixed Concrete Over Site Mixed Concrete</h3>
                    </div>
                    <Row>
                        {benefits.map((benefit, index) => {
                            return (
                                <div className="benefits">
                                    <img src={benefit.icon} alt={benefit.name} />
                                    <h4>{benefit.name}</h4>
                                    <p>{benefit.description}</p>
                                </div>
                            )
                        })}
                    </Row>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle2} alt="Vehicle" />
                </Marquee>
            </section>
            <section>
                <Container>
                    <div className="text-center">
                        <h2>RMC MACHINERY</h2>
                        <h3 className="type-2">We Have Heavy Duty Machineries</h3>
                    </div>
                </Container>
                <Machinery />
            </section>
            <GetInTouch />
        </div>
    )
}

export default Rmc
