import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ContactForm from '../components/ContactForm'

const ContactUs = () => {
    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Contact Us</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>
                    <h2>Get In Touch</h2>
                    <h3>We are always ready to solve your problem</h3>
                    <Row>
                        <Col md={9}>
                            <ContactForm />
                        </Col>
                        <Col md={3} xs={12} className="contact-info my-auto">
                            <div className="icon mt-unset"><RoomIcon /></div>
                            <h4>Address</h4>
                            <p>Saykha Gidc,<br /> Ta. Vagra, Dist Bharuch,<br /> Gujarat - 392165</p>
                            <div className="icon"><EmailIcon /></div>
                            <h4>Email</h4>
                            <p><a href="mailto:info@conmixinfra.com">info@conmixinfra.com</a></p>
                            <div className="icon"><PhoneIcon /></div>
                            <h4>Phone</h4>
                            <p><a href="tel:+91 98791 30080">+91 98791 30080</a></p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ContactUs
