import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Machinery1 from '../assets/images/gallery/machinery-1.jpg'
import Machinery2 from '../assets/images/gallery/machinery-2.jpg'
import Machinery3 from '../assets/images/gallery/machinery-3.jpg'
import Machinery4 from '../assets/images/gallery/machinery-4.jpg'
import Machinery5 from '../assets/images/gallery/machinery-5.jpg'
import Machinery6 from '../assets/images/gallery/machinery-6.jpg'
import Plant1 from '../assets/images/gallery/plant-1.jpg'
import Plant2 from '../assets/images/gallery/plant-2.jpg'
import Plant3 from '../assets/images/gallery/plant-3.jpg'
import Plant4 from '../assets/images/gallery/plant-4.jpg'
import Plant5 from '../assets/images/gallery/plant-5.jpg'
import Plant6 from '../assets/images/gallery/plant-6.jpg'
import PlantTm1 from '../assets/images/gallery/plant-tm-1.jpg'
import PlantTm2 from '../assets/images/gallery/plant-tm-2.jpg'
import Tm1 from '../assets/images/gallery/tm-1.jpg'
import GetInTouch from '../components/GetInTouch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
};

const tabImagesAll = [Plant1, PlantTm1, Plant4, Tm1, Plant2, Machinery3]
const tabImagesPlant = [Plant1, Plant2, Plant3, Plant4, Plant5, Plant6]
const tabImagesTm = [PlantTm1, PlantTm2, Tm1]
const tabImagesMachinery = [Machinery1, Machinery2, Machinery3, Machinery4, Machinery5, Machinery6]

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Gallery = () => {
    const [value, setValue] = useState(0);
    const [finalImage, setFinalImage] = useState(null)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleOpen = (image) => {
        setFinalImage(image)
        setOpen(true);
    }

    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Gallery</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </section>
            <section className="gallery">
                <Container>
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="Plant" {...a11yProps(1)} />
                                <Tab label="TM" {...a11yProps(2)} />
                                <Tab label="Machinery" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Row>
                                {tabImagesAll.map((image, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <img onClick={() => handleOpen(image)} src={image} alt="Conmix Infra Gallery" className="img-fluid" />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Row>
                                {tabImagesPlant.map((image, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <img onClick={() => handleOpen(image)} src={image} alt="Conmix Infra Gallery" className="img-fluid" />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Row>
                                {tabImagesTm.map((image, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <img onClick={() => handleOpen(image)} src={image} alt="Conmix Infra Gallery" className="img-fluid" />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Row>
                                {tabImagesMachinery.map((image, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <img onClick={() => handleOpen(image)} src={image} alt="Conmix Infra Gallery" className="img-fluid" />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPanel>
                    </Box>
                </Container>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={finalImage} alt="Conmix Infra Gallery" className="img-fluid" />
                </Box>
            </Modal>
            <GetInTouch />
        </div>
    )
}

export default Gallery
